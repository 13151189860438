import api from '@/api'
export default {
    actions: {
        async GetDistrict(_, District_id) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params("GetDistrict",{id:District_id}),
            })
            return response.data;
        },
    }
}