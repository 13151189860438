import api from "@/api";
import axios from "@/core/axios";
export default {
	actions: {
		async ListProvinces() {
			const response = await axios({
				method: "GET",
				url: api.ListProvinces,
			});
			return response.data;
		},
		async ListDistricts(_, id) {
			let params = {
				province: id,
			};
			const response = await axios({
				method: "GET",
				url: api.ListDistricts,
				params: params,
			});
			return response.data;
		},
		async ListWards(_, id) {
			let params = {
				district: id,
			};
			const response = await axios({
				method: "GET",
				url: api.ListWards,
				params: params,
			});
			return response.data;
		},
	},
};
