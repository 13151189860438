import api from '@/api';

export default {
  actions: {
    async ListApps(_, options = {}) {
      let response = await this.$axios({
        method: 'GET',
        url: api.ListApps,
        params: options,
      });
      return response.data;
    },
  },
};
