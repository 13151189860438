const routes = [
	{
		name: "web",
		path: "/",
		alias: "/home",
		component: () => import("@/pages"),
		children: [
			{
				name: "web.slug",
				path: "/:slug",
				component: () => import("@/components/slug"),
				children: [
					{
						name: "web.slug.home",
						path: "home",
						component: () => import("@/pages/home"),
					},
					{
						name: "web.rural-digital-services",
						path: "rural-digital-services",
						redirect: { name: "web.slug.rural-digital-services.list" },
						component: () => import("@/pages/rural-digital-services"),
						children: [
							{
								name: "web.slug.rural-digital-services.detail",
								path: ":id",
								component: () =>
									import("@/pages/rural-digital-services/detail"),
							},
							{
								name: "web.slug.rural-digital-services.list",
								path: "list",
								component: () => import("@/pages/rural-digital-services/list"),
							},
						],
					},
					{
						name: "web.slug.smart-devices",
						path: "smart-devices",
						redirect: { name: "web.slug.smart-devices.list" },
						component: () => import("@/pages/smart-devices"),
						children: [
							{
								name: "web.slug.smart-devices.list",
								path: "list",
								component: () => import("@/pages/smart-devices/list"),
							},
							{
								name: "web.slug.smart-devices.detail",
								path: "detail/:id",
								component: () => import("@/pages/smart-devices/detail"),
							},
						],
					},
					{
						name: "web.slug.videos",
						path: "videos",
						redirect: { name: "web.slug.videos.list" },
						component: () => import("@/pages/videos"),
						children: [
							{
								name: "web.slug.videos.list",
								path: "list",
								component: () => import("@/pages/videos/list"),
							},
						],
					},
					{
						name: "web.slug.news",
						path: "news",
						redirect: { name: "web.slug.news.list" },
						component: () => import("@/pages/news"),
						children: [
							{
								name: "web.slug.news.list",
								path: "list",
								component: () => import("@/pages/news/list"),
							},
							{
								name: "web.slug.news.detail",
								path: ":id/detail",
								component: () => import("@/pages/news/detail"),
							},
						],
					},
					{
						name: "web.slug.knowledge-library",
						path: "knowledge-library",
						redirect: { name: "web.slug.knowledge-library.list" },
						component: () => import("@/pages/knowledge-library"),
						children: [
							{
								name: "web.slug.knowledge-library.list",
								path: "list",
								component: () => import("@/pages/knowledge-library/list"),
							},
							{
								name: "web.slug.knowledge-library.detail",
								path: ":id/detail",
								component: () => import("@/pages/knowledge-library/detail"),
							},
						],
					},
					{
						name: "web.slug.support-services",
						path: "support-services",
						redirect: { name: "web.slug.support-services" },
						component: () => import("@/pages/support-services"),
						children: [
							{
								name: "web.slug.support-services.freq-asked-questions",
								path: "freq-asked-questions",
								component: () =>
									import("@/pages/support-services/freq-asked-questions"),
							},
							{
								name: "web.slug.support-services.feedback",
								path: "feedback",
								component: () => import("@/pages/support-services/feedback"),
							},
							{
								name: "web.slug.support-services.video-tutorials",
								path: "video-tutorials",
								component: () =>
									import("@/pages/support-services/video-tutorials"),
							},
						],
					},
					{
						name: "web.slug.smart-solutions",
						path: "smart-solutions",
						redirect: { name: "web.slug.smart-solutions.list" },
						component: () => import("@/pages/smart-solutions"),
						children: [
							{
								name: "web.slug.smart-solutions.list",
								path: "list",
								component: () => import("@/pages/smart-solutions/list"),
							},
							{
								name: "web.slug.smart-solutions.detail",
								path: "detail/:id",
								component: () => import("@/pages/smart-solutions/detail"),
							},
						],
					},
					{
						name: "web.slug.contact",
						path: "contact",
						component: () => import("@/pages/contact"),
					},
					{
						name: "web.slug.images",
						path: "images",
						redirect: { name: "web.slug.images.list" },
						component: () => import("@/pages/images"),
						children: [
							{
								name: "web.slug.images.list",
								path: "list",
								component: () => import("@/pages/images/list"),
							},
							{
								name: "web.slug.images.detail",
								path: "detail/:id",
								component: () => import("@/pages/images/detail"),
							},
						],
					},
					{
						name: "web.slug.notifications",
						path: "notifications",
						redirect: { name: "web.slug.notifications.list" },
						component: () => import("@/pages/notifications"),
						children: [
							{
								name: "web.slug.notifications.list",
								path: "list",
								component: () => import("@/pages/notifications/list"),
							},
							{
								name: "web.slug.warnings.list",
								path: "/:slug/warnings",
								component: () => import("@/pages/notifications/warnings"),
							},
							{
								name: "web.slug.notifications.detail",
								path: "detail/:id",
								component: () => import("@/pages/notifications/detail"),
							},
							{
								name: "web.slug.warnings.detail",
								path: "detail/:id",
								component: () => import("@/pages/notifications/detail"),
							},
						],
					},
				],
			},
		],
	},
];

export default routes;
