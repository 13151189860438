<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: "App",
  components: {
  },
};
</script>

<style>
.container{
  max-width: 1200px !important;
}
.modal-open {
  padding-right: 0px !important;
}
@media (min-width: 992px) {
  .modal-open {
    padding-right: 10px !important;
  }
}
</style>
