import api from '@/api'
export default {
    actions: {
        async ListNotifications(_,params) {
            const response = await this.$axios({
                method: 'GET',
                url: api.ListNotifications,
                params: params
            })
            return response.data;
        },
        async GetNotificationDetail(_, NotificationId) {
            const response = await this.$axios({
                method: 'GET',
                url: api.params("GetNotificationDetail", { id: NotificationId })
            })
            return response.data
        },
    }
}