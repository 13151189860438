import moment from "moment";

export default (app) => {
	app.config.globalProperties.$filters = {
		dateTime: (value, format = "DD/MM/YYYY HH:mm") => {
			return moment(value).utcOffset(7).format(format);
		},
		date: (value) => {
			return moment(value).utcOffset(7).format("DD/MM/YYYY");
		},
		htmlToText: (content) => {
			content??=''
			return content.replace(/<[^>]*>/g, "").replaceAll("&nbsp;", "");
		},
		money: (money) => {
			return money.toLocaleString("en-US").replace(/,/g, ".") + "đ";
		},
	};
};
