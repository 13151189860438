/* eslint-disable no-prototype-builtins */
let config = {
	host: process.env.VUE_APP_HOST,
	host_user: process.env.VUE_APP_USER,
	api: process.env.VUE_APP_HOST_API,

	GetWard: "/api/web/wards-slug/{slug}",
	// Home banner
	ListBanners: "/api/web/configs/main-banner-images",
	GetBanner: "/api/user/banners/{id}",

	// Menu
	ListMenu: "/api/web/menus",

	ListImages: "/api/web/images",
	GetImage: "/api/web/images/{id}",

	ListSmartDevices: "/api/web/smart-devices",
	GetSmartDeviceDetail: "/api/web/smart-devices/{id}",
	SmartDeviceType: "/api/web/smart-device-types",

	ListNews: "/api/web/articles",
	GetNews: "/api/web/articles/{id}",

	ListArticleTypes: "/api/web/article-category",

	ListRuralNetworks: "/api/web/rural-networks",

	ListVideos: "/api/web/videos",

	GetSmartSolutionHome: "/api/web/home-smart-solutions",

	ListRuralServices: "/api/web/rural-digital-services",
	GetRuralServicesDetail: "/api/web/rural-digital-services/{id}",

	ListServiceManagements: "/api/web/service-management",

	ListProvinces: "/api/web/provinces",
	ListDistricts: "/api/web/districts",
	ListWards: "/api/web/wards",

	ListFreqAskedQuestions: "/api/web/freq-asked-questions",
	ListVideoTutorials: "/api/web/video-tutorials",
	CreateFeedbacks: "/api/web/feedbacks",

	ListSmartSolutions: "/api/web/smart-solutions",
	GetSmartSolutions: "/api/web/smart-solutions/{id}",
	
	GetContact: "/api/web/contact/{slug}",
	CreateContact: "/api/web/ward-contact",

	GetDistrict:"/api/web/districts/{id}",
	ListNotifications:'/api/web/notifications',
	GetNotificationDetail:'/api/web/notification/{id}',

	ListknowledgeLibraryArticles: '/api/web/library-articles',
	ListknowledgeLibraryVideos: '/api/web/library-videos',
	GetknowledgeLibraryArticle: '/api/web/library-article/{id}',
	GetknowledgeLibraryVideo: '/api/web/library-video/{id}',
	ListCategoryknowledgeLibrary: '/api/web/knowledge-categories',
};

let api = new Proxy(config, {
	get(target, name) {
		if (name === "host_user") return Reflect.get(target, name);
		if (name !== "params") {
			return (
				Reflect.get(target, "api").replace(/\/$/, "") +
				Reflect.get(target, name)
			);
		} else {
			return Reflect.get(target, name);
		}
	},
});

api.params = (name, options) => {
	try {
		let endpoint = api[name];
		for (let value in options) {
			if (value && options.hasOwnProperty(value)) {
				endpoint = endpoint.replace(`{${value}}`, options[value]);
			}
		}
		return endpoint;
	} catch (e) {
		console.log(e);
	}
};

export default api;
